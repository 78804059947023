<script setup>
import { useI18n } from "vue-i18n";
const { t } = useI18n();

let gratisItems = [
  {
    title: t("home.advantagesCards.familyCard.features.0"),
    available: true,
  },
  {
    title: t("home.advantagesCards.familyCard.features.1"),
    available: true,
  },
  {
    title: t("home.advantagesCards.familyCard.features.2"),
    available: true,
  },
  {
    title: t("home.advantagesCards.familyCard.features.3"),
    available: true,
  },
  {
    title: t("home.advantagesCards.familyCard.features.4"),
    available: true,
  },
  {
    title: t("home.advantagesCards.familyCard.features.5"),
    available: true,
  },
  {
    title: t("home.advantagesCards.familyCard.features.6"),
    available: true,
  },
  {
    title: t("home.advantagesCards.familyCard.features.7"),
    available: true,
  },
  {
    title: t("home.advantagesCards.familyCard.features.8"),
    available: false,
  },
  {
    title: t("home.advantagesCards.familyCard.features.9"),
    available: false,
  },
  {
    title: t("home.advantagesCards.familyCard.features.10"),
    available: false,
  },
  {
    title: t("home.advantagesCards.familyCard.features.11"),
    available: false,
  },
  {
    title: t("home.advantagesCards.familyCard.features.12"),
    available: false,
  },
  {
    title: t("home.advantagesCards.familyCard.features.13"),
    available: false,
  },
];
let premiumItems = [
  {
    title: t("home.advantagesCards.familyVip.features.0"),
    available: true,
  },
  {
    title: t("home.advantagesCards.familyVip.features.1"),
    available: true,
  },
  {
    title: t("home.advantagesCards.familyVip.features.2"),
    available: true,
  },
  {
    title: t("home.advantagesCards.familyVip.features.3"),
    available: true,
  },
  {
    title: t("home.advantagesCards.familyVip.features.4"),
    available: true,
  },
  {
    title: t("home.advantagesCards.familyVip.features.5"),
    available: true,
  },
  {
    title: t("home.advantagesCards.familyVip.features.6"),
    available: true,
  },
  {
    title: t("home.advantagesCards.familyVip.features.7"),
    available: true,
  },
  {
    title: t("home.advantagesCards.familyVip.features.8"),
    available: true,
  },
  {
    title: t("home.advantagesCards.familyVip.features.9"),
    available: true,
  },
  {
    title: t("home.advantagesCards.familyVip.features.10"),
    available: true,
  },
  {
    title: t("home.advantagesCards.familyVip.features.11"),
    available: true,
  },
  {
    title: t("home.advantagesCards.familyVip.features.12"),
    available: true,
  },
  {
    title: t("home.advantagesCards.familyVip.features.13"),
    available: true,
  },
];

const subscriptionItems = [
  { items: gratisItems, isPremium: false },
  { items: premiumItems, isPremium: true },
];
const title = t("home.advantagesCards.title");
const description = t("home.advantagesCards.subTitle");

const cookie = useCookie("authenticated");
const isLoggedIn = ref(computed(() => (cookie.value ? true : false)));
</script>
<template>
  <Title>{{ title }}</Title>
  <Meta name="description" :content="description" />
  <div class="container px-5 pt-6 md:pt-8">
    <HomepageTopBanner />
    <HomepageAdvantagesOfClubSection>
      <HomepageSubscriptionCard
        :items="gratisItems"
        class="hidden md:block ml-2"
      />
      <HomepageSubscriptionCard
        isPremium
        class="relative hidden md:block ml-1.5"
        :items="premiumItems"
        buttonTitle="ACQUISTA LA TUA VIP CARD"
      />

      <div class="mt-5 md:hidden col-span-2 swiper__subscription_cards">
        <SwiperSubscriptionCardsCarousel
          :subscription-items="subscriptionItems"
        />
      </div>
    </HomepageAdvantagesOfClubSection>
  </div>

  <HomepageVipBenefitsSection />
  <HomepageFaqs />
  <HomepageLoginBanner v-if="!isLoggedIn" />
</template>
