<script setup>
import { Pagination, EffectCreative } from "swiper/modules";

defineProps({
  subscriptionItems: {
    type: Array,
    required: true,
  },
});
const subscriptionCard = resolveComponent("HomepageSubscriptionCard");
</script>

<template>
  <div>
    <Swiper
      :modules="[Pagination, EffectCreative]"
      :slides-per-view="1"
      :space-between="10"
      :breakpoints="{
        300: {
          slidesPerView: 1.1,
          spaceBetween: 10,
        },
        380: {
          slidesPerView: 1.2,
          spaceBetween: 10,
        },
        480: {
          slidesPerView: 1.3,
          spaceBetween: 10,
        },

        640: {
          slidesPerView: 1.4,
          spaceBetween: 10,
        },
      }"
      :pagination="{ clickable: true }"
    >
      <SwiperSlide v-for="slide in subscriptionItems" :key="slide">
        <component
          class="mt-5 mx-auto items-center"
          :is="subscriptionCard"
          :items="slide.items"
          :is-premium="slide.isPremium"
        />
      </SwiperSlide>
    </Swiper>
  </div>
</template>
